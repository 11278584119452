import React, { useEffect } from "react";

const ZENDESK_KEY = process.env.REACT_APP_ZENDESK_KEY;

// Extend the Window interface to include zESettings
declare global {
  interface Window {
    zE: (...args: any[]) => void;
  }
}

const settings = {
  color: {
    theme: "#4e40f3",
  },
  launcher: {
    chatLabel: {
      "en-US": "Need Help",
    },
  },
  contactForm: {
    fields: [{ id: "description", prefill: { "*": "" } }],
  },
};

const ZendeskLoader: React.FC = () => {
  useEffect(() => {
    if (!ZENDESK_KEY) return;

    const script = document.createElement("script");
    script.id = "ze-snippet";
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_KEY}`;
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (window.zE) {
        window.zE("webWidget", "set", settings);
        window.zE("webWidget", "hide"); // Ensure it's hidden initially
        window.zE("webWidget:on", "close", () => {
          window.zE("webWidget", "hide");
        });
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null; // No UI needed
};

export default ZendeskLoader;
