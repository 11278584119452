import HeartFilledIcon from "assets/heart_filled.svg";
import HeartOutlinedIcon from "assets/heart_outlined.svg";
import ShareIcon from "assets/share.svg";
import { useAuth } from "context/authContext";
import { useToast } from "components/common/toast/toastService";

interface Props {
  name: string;
  logoUrl: string | null;
  isInWishlist?: boolean;
  onAddToFavorite?: () => void;
  discount?: number;
  brandId?: string;
}

export const InfoWithLogoCard = ({
  name,
  logoUrl,
  isInWishlist,
  onAddToFavorite,
  discount,
  brandId,
}: Props) => {
  const { isLoggedIn } = useAuth();
  const toast = useToast();

  const base64UrlEncode = (str: string) => {
    return btoa(str).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
  };

  const handleShareClick = () => {
    if (!brandId) {
      toast.open("Error!", "Brand ID is missing.", "error", 4000);
      return;
    }

    const APP_ID = process.env.REACT_APP_BINANCE_APP_ID;
    const path = "pages/brandDetail/index";
    const query = `brandId=${brandId}`;

    const encodedPath = base64UrlEncode(path);
    const encodedQuery = base64UrlEncode(query);

    const shareUrl = `bnc://app.binance.com/mp/app?appId=${APP_ID}&startPagePath=${encodedPath}&startPageQuery=${encodedQuery}`;

    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        toast.open(
          "Copied!",
          "Share link copied to clipboard.",
          "success",
          4000
        );
      })
      .catch((err) => console.error("Failed to copy:", err));
  };

  return (
    <div className="relative">
      <div className="absolute top-0 right-0 flex flex-col items-end p-2">
        {isLoggedIn && (
          <img
            alt="wishlist-icon"
            src={isInWishlist ? HeartFilledIcon : HeartOutlinedIcon}
            className="h-5 w-5 transform transition-transform hover:scale-110 cursor-pointer"
            onClick={onAddToFavorite}
          />
        )}
        <img
          alt="share-icon"
          src={ShareIcon}
          className="mt-3 h-5 w-5 transform transition-transform hover:scale-110 cursor-pointer"
          onClick={handleShareClick}
        />
      </div>

      <div className="flex justify-center mt-4 ">
        <img
          alt="logo"
          src={logoUrl || ""}
          className="h-32 max-w-56 rounded-large relative"
        />

        {discount && discount > 0 && (
          <div className="absolute bottom-[2.75rem] left-[6.75rem] bg-rose-600 text-white font-semibold text-sm px-2 py-1 rounded-md">
            -{discount}%
          </div>
        )}
      </div>

      <div className="mt-2 text-lg font-medium text-primary text-center">
        {name}
      </div>
    </div>
  );
};
