import { Header } from "components/common/header";
import { InfoWithLogoCard } from "components/layout/infoWithLogoCard";
import { DetailTab } from "components/pages/product/components/detailTab";
import ReactCountryFlag from "react-country-flag";
import { useCallback, useContext, useEffect, useState } from "react";
import { getBrandById } from "api/getBrandById";
import { Ratings } from "components/layout/ratings";
import { useNavigate, useParams } from "react-router-dom";
import { Select } from "components/common/select";
import Button from "components/common/button/button";
import { TextField } from "components/common/textField";
import { CartItem } from "models/cartItem";
import { AppContext } from "context/appContext";
import { Option } from "models/options";
import {
  DEFAULT_PRICE_FIXED_DECIMALS,
  MAIN_CRYPTO_CURRENCY,
  USD_TO_USDT,
} from "utils/constants";
import { routes } from "routes/routes";
import { Brand } from "models/brandDetail";
import { Product } from "models/product";
import ProductDetailPageLoader from "components/pages/productDetail/loader/ProductDetailPageLoader";
import { Cart } from "models/cartItem";
import { addToBasket } from "api/addToBasket";
import { useToast } from "components/common/toast/toastService";
import { useAuth } from "context/authContext";
import { removeFromWishlist } from "api/removeFromWishlist";
import { addToWishList } from "api/addToWishlist";
import { loadCartFromLocalStorage } from "hooks/useCart";
import { useErrorBoundary } from "utils/handleErrorBoundary";

export const ProductDetail = () => {
  const navigate = useNavigate();
  const { cart, setCart } = useContext(AppContext);
  const [brand, setBrand] = useState<Brand>();
  const [rating, setRating] = useState(0);
  const params: { [key: string]: any } = useParams();
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState<Product[]>();
  const [faceValue, setFaceValue] = useState<string>("");
  const [quantity, setQuantity] = useState<string>("");
  const [total, setTotal] = useState<number>(0);
  const { isLoggedIn } = useAuth();
  const [isInWishlist, setIsInWishlist] = useState(
    brand?.isInWishlist ?? false
  );
  const [errorDetails, setErrorDetails] = useState<string | null>(null);
  const handleErrorBoundary = useErrorBoundary();

  const toast = useToast();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const brand = await getBrandById(params.id);
        if (brand) {
          setBrand(brand);
        }
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, [params.id]);

  useEffect(() => {
    if (brand?.products && brand?.products.length > 0) {
      const defaultProduct = brand.products.reduce((prev, current) =>
        current.maxFaceValue > prev.maxFaceValue ? current : prev
      );
      const selected = {
        ...defaultProduct,
        name: `${defaultProduct.faceValueType === "Fixed"
          ? defaultProduct.name
          : `${defaultProduct.minFaceValue}-${defaultProduct.maxFaceValue}`
          }`,
        value: defaultProduct.id,
      };

      const options = brand.products
        .slice()
        .sort((a, b) => b.maxFaceValue - a.maxFaceValue)
        .map((product: Product) => ({
          ...product,
          value: product.id,
          name: getProductDisplayName(product),
        }));

      setProducts(options);
      setSelectedProduct(selected);
    }
  }, [brand]);

  useEffect(() => {
    if (brand) {
      setIsInWishlist(brand.isInWishlist ?? false);
    }
  }, [brand]);

  const calculateTotal = useCallback(() => {
    if (!selectedProduct) return 0;
    const {
      faceValueType,
      minPrice,
      defaultCurrencyRate,
      faceValue,
      minFaceValue,
    } = selectedProduct;
    const quantityValue = +quantity ?? 0;
    const rate = +defaultCurrencyRate;

    let total = 0;

    if (faceValueType === "Fixed") {
      total = (minPrice * quantityValue) / rate;
    } else if (faceValueType === "Ranged" && faceValue) {
      const calculatedValue = (faceValue * minPrice) / minFaceValue;
      total = (calculatedValue * quantityValue) / rate;
    }

    setTotal(parseFloat(total.toFixed(DEFAULT_PRICE_FIXED_DECIMALS)));
  }, [selectedProduct, quantity]);

  useEffect(() => {
    if (selectedProduct) {
      calculateTotal();
    }
  }, [selectedProduct, faceValue, quantity, calculateTotal]);

  const getProductDisplayName = (product: Product) => {
    const isRanged = product.faceValueType === "Ranged";
    const priceRange = isRanged
      ? `(${(Number(product.minPrice) / Number(product.defaultCurrencyRate)).toFixed(
        DEFAULT_PRICE_FIXED_DECIMALS
      )} - ${(Number(product.maxPrice) / Number(product.defaultCurrencyRate)).toFixed(
        DEFAULT_PRICE_FIXED_DECIMALS
      )} USD)`
      : "";

    return `${product.name} ${isRanged ? `-${product.maxFaceValue}` : ""
      } ${priceRange}`;
  };

  const handleAddToFavorite = async (brand: any) => {
    try {
      if (isInWishlist) {
        await removeFromWishlist(brand.id);
        setIsInWishlist(false);
      } else {
        await addToWishList(brand.id);
        setIsInWishlist(true);
      }
    } catch (error) {
      console.error("Failed to update wishlist", error);
    }
  };

  const addItemToCart = async (newCartItem: CartItem) => {
    try {
      if (selectedProduct === undefined) return;
      const data = {
        unitFaceValue: newCartItem.unitFaceValue,
        quantity: newCartItem.quantity,
        unitPrice: newCartItem.unitPrice,
        productId: newCartItem.productId,
      };

      const addedItem = await addToBasket(data);
      toast.open("Success", "Item successfully added to cart", "success", 5000);

      setCart((prevCart: Cart) => {
        const updatedItems = prevCart?.basketItems.map((item: CartItem) =>
          item.productId === newCartItem.productId &&
            item.unitFaceValue === newCartItem.unitFaceValue
            ? { ...item, id: addedItem.data.id }
            : item
        );
        return {
          ...prevCart,
          basketItems: updatedItems,
        };
      });
      setErrorDetails(null);
    } catch (error: any) {
      console.error("##debug Full Error Response: ", error);
      handleErrorBoundary(error);
    }
  };

  const addItemToLocalStorageCart = (newCartItem: CartItem) => {
    const localCart: Partial<Cart> = loadCartFromLocalStorage();
    const updatedItems = localCart.basketItems?.some(
      (item) =>
        item.productId === newCartItem.productId &&
        item.unitFaceValue === newCartItem.unitFaceValue
    )
      ? localCart.basketItems?.map((item) =>
        item.productId === newCartItem.productId &&
          item.unitFaceValue === newCartItem.unitFaceValue
          ? { ...item, quantity: item.quantity + newCartItem.quantity }
          : item
      )
      : [...(localCart.basketItems || []), newCartItem];

    const updatedCart: Partial<Cart> = {
      ...localCart,
      basketItems: updatedItems,
      totalItemQuantity: updatedItems?.reduce(
        (sum, item) => sum + item.quantity,
        0
      ),
      totalPrice: updatedItems?.reduce(
        (sum, item) => sum + item.unitPrice * item.quantity,
        0
      ),
    };

    localStorage.setItem("cart", JSON.stringify(updatedCart));
    setCart(updatedCart as Cart);
  };

  const onAddToCart = () => {
    if (selectedProduct) {
      const {
        defaultCurrencyRate,
        faceValueType,
        minPrice,
        minFaceValue,
        faceValue,
      } = selectedProduct;

      const rate = +defaultCurrencyRate;

      const calculatedUnitPrice = () => {
        if (faceValueType === "Fixed") {
          return (minPrice / rate) * USD_TO_USDT;
        } else if (faceValueType === "Ranged" && faceValue) {
          const calculatedValue = (faceValue * minPrice) / minFaceValue;
          return (calculatedValue / rate) * USD_TO_USDT;
        }
        return 0;
      };

      const unitPrice = calculatedUnitPrice();

      if (!unitPrice || isNaN(unitPrice)) {
        console.error("Error calculating unit price:", unitPrice);
        return;
      }

      const newCartItem: CartItem = {
        id: selectedProduct.id,
        productId: selectedProduct.id,
        quantity: +(quantity ?? 0),
        brandLogoUrl: brand?.logoUrl ?? "",
        brandName: brand?.name ?? "",
        currency: selectedProduct.currencyCode ?? "USD",
        unitPrice: parseFloat(unitPrice.toFixed(DEFAULT_PRICE_FIXED_DECIMALS)),
        unitFaceValue:
          selectedProduct.faceValueType === "Fixed"
            ? selectedProduct.minFaceValue
            : faceValue ?? 0,
      };

      setCart((prevCart: Cart) => {
        const existingProduct = prevCart?.basketItems?.find((item: CartItem) =>
          selectedProduct.faceValueType === "Fixed"
            ? item.productId === selectedProduct.id
            : item.productId === selectedProduct.id &&
            +faceValue! === item.unitFaceValue
        );

        const updatedItems = existingProduct
          ? prevCart?.basketItems?.map((item: CartItem) =>
            item.productId === existingProduct.productId &&
              item.unitFaceValue === existingProduct.unitFaceValue
              ? { ...item, quantity: item.quantity + newCartItem.quantity }
              : item
          )
          : [...(prevCart?.basketItems ?? []), newCartItem];

        const updatedCart = {
          ...prevCart,
          basketItems: updatedItems,
          totalItemQuantity: updatedItems.reduce(
            (sum, item) => sum + item.quantity,
            0
          ),
          totalPrice: updatedItems.reduce(
            (sum, item) => sum + item.unitPrice * item.quantity,
            0
          ),
        };

        // Save to local storage if not logged in
        if (!isLoggedIn) {
          localStorage.setItem("cart", JSON.stringify(updatedCart));
        }

        return updatedCart;
      });

      // Add or update the product in the cart on the server
      if (isLoggedIn) {
        addItemToCart(newCartItem);
      }

      setQuantity("");
      setFaceValue("");
    }
  };

  const onSelectProduct = (option: Option) => {
    const product = products?.find((x: Product) => x.id === option.value);
    if (product) {
      if (product.stockCount === 0) {
        toast.open("Error", "This product is out of stock.", "error", 3000);
        return;
      }
      setSelectedProduct(product);
      setFaceValue("");
      setQuantity("");
    }
  };

  const onChangeFaceValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    // Allow entering any number but prevent non-numeric values
    if (/^\d*$/.test(value)) {
      setFaceValue(value);

      // Set the product face value only if it's a valid number
      if (selectedProduct) {
        const updatedProduct = {
          ...selectedProduct,
          faceValue: +value,
        };
        setSelectedProduct(updatedProduct);
      }
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    if ([".", "e", "+", "-"].includes(event.key)) {
      event.preventDefault();
    }
  };

  const onChangeCount = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    const { isValid, value: validatedValue } = validateInput(
      value,
      1,
      Infinity
    );

    if (isValid) {
      setQuantity(validatedValue ? (validatedValue as string) : "");
    }
  };

  const getTotalInUsdt = () => {
    return (total * USD_TO_USDT).toFixed(DEFAULT_PRICE_FIXED_DECIMALS);
  };

  const onBuynow = () => {
    try {
      onAddToCart();
      navigate(routes.checkout);
    } catch (error) {
      handleErrorBoundary(error);
    }
  };

  type ValidationResult = {
    isValid: boolean;
    value: number | string;
  };

  const validateInput = (
    value: string,
    min: number,
    max: number
  ): ValidationResult => {
    if (!value || /^\d*$/.test(value)) {
      const numericValue = parseInt(value, 10);
      if (!isNaN(numericValue) && numericValue >= min && numericValue <= max) {
        return { isValid: true, value: numericValue };
      } else if (value === "") {
        return { isValid: true, value: "" };
      }
    }
    return { isValid: false, value };
  };

  const enableBuyNowOrAddToCart = (): boolean => {
    if (!selectedProduct) return true;
    const { faceValueType, minFaceValue, maxFaceValue, stockCount } =
      selectedProduct;

    // Disable only if stockCount is explicitly 0
    if (stockCount === 0) {
      return true;
    }

    // For fixed products, only check if quantity is valid
    if (faceValueType === "Fixed") {
      return (+quantity ?? 0) < 1;
    }
    // For ranged products, check both faceValue and quantity
    return (
      (+quantity ?? 0) < 1 ||
      +faceValue < (minFaceValue || 0) ||
      +faceValue > (maxFaceValue || Infinity)
    );
  };

  return (
    <div className="pt-1 hide-scrollbar scrollable-section">
      <Header headerTitle="Gift cards" onNavigateBack={() => navigate(-1)} />

      {loading && <ProductDetailPageLoader />}

      {!loading && brand && (
        <>
          <InfoWithLogoCard
            name={brand?.name}
            logoUrl={brand?.logoUrl}
            isInWishlist={isInWishlist}
            onAddToFavorite={() => handleAddToFavorite(brand)}
            discount={brand?.discount}
            brandId={brand?.id}
          />

          <div className="flex justify-center -mt-1">
            <Ratings starsCount={5} rating={rating} setRating={setRating} />
          </div>

          {brand?.countryCode && (
            <div className="text-left mt-4 ml-3">
              <ReactCountryFlag
                countryCode={brand?.countryCode}
                svg
                className="rounded-md h-6"
              />
              <span className="text-xs font-medium ml-2 text-primary">{`Redeemable in ${brand?.countryCode}`}</span>
            </div>
          )}

          <div>
            <DetailTab
              details={{
                Terms: `${brand?.terms || ""}`,
                Description: `${brand.description || ""}`,
                "How To Redeem": `${brand.redemptionInstructions || ""}`,
              }}
            />
          </div>

          <div className="mt-5 text-left">
            <div className="text-sm font-medium text-primary ml-1">
              {products &&
                products.length > 0 && (
                  <>
                    {products[0].faceValueType !== "Fixed" ? (
                      <>
                        <div>
                          Enter Amount between {products[0].minFaceValue} - {products[0].maxFaceValue}:
                        </div>
                        <div>
                          (Price: USD{" "}
                          {(
                            Number(products[0].minPrice) / Number(products[0].defaultCurrencyRate)
                          ).toFixed(DEFAULT_PRICE_FIXED_DECIMALS)}{" "}
                          -{" "}
                          {(
                            Number(products[0].maxPrice) / Number(products[0].defaultCurrencyRate)
                          ).toFixed(DEFAULT_PRICE_FIXED_DECIMALS)})
                        </div>
                      </>
                    ) : (
                      "Select Amount:"
                    )}
                  </>
                )}
            </div>
            <div className="w-full max-w-sm">
              {products &&
                products.length > 0 &&
                // Check if the product is range or fixed
                (products[0].faceValueType === "Fixed" ? (
                  <Select
                    options={products
                      .slice()
                      .sort((a, b) => b.maxFaceValue - a.maxFaceValue)
                      .map((product: Product) => ({
                        value: product.id,
                        name: `${product.name} ${product.stockCount === 0
                          ? "(Out of Stock)"
                          : product.stockCount === null
                            ? ""
                            : ""
                          } (USD ${(
                            Number(product.minPrice) / Number(product.defaultCurrencyRate)
                          ).toFixed(DEFAULT_PRICE_FIXED_DECIMALS)})`,
                        disabled: product.stockCount === 0,
                      }))}
                    selectedValue={
                      selectedProduct
                        ? {
                          value: selectedProduct.id,
                          name: `${selectedProduct.name} (USD ${(
                            Number(selectedProduct.minPrice) /
                            Number(selectedProduct.defaultCurrencyRate)
                          ).toFixed(DEFAULT_PRICE_FIXED_DECIMALS)})`,
                        }
                        : { value: "", name: "" }
                    }
                    onChangeSelect={onSelectProduct}
                    showRadioButtons
                    className="text-[11px] !important"
                    optionClassName="text-[11px]"
                  />

                ) : (
                  <>
                    <TextField
                      value={faceValue}
                      onChange={onChangeFaceValue}
                      onKeyDown={handleKeyDown}
                      className={`mt-2 border border-selected focus:border-primary-blue focus:outline-none text-sm font-normal text-primary shadow-none ${faceValue &&
                        (+faceValue < (selectedProduct?.minFaceValue || 0) ||
                          +faceValue >
                          (selectedProduct?.maxFaceValue || Infinity)) &&
                        "border border-error-info"
                        }`}
                      type="number"
                    />
                    {(!selectedProduct ||
                      +faceValue < selectedProduct?.minFaceValue ||
                      +faceValue > selectedProduct?.maxFaceValue) &&
                      faceValue && (
                        <p className="ml-1 text-xs font-normal text-error-info">
                          Please enter a valid value between{" "}
                          {selectedProduct?.minFaceValue} -{" "}
                          {selectedProduct?.maxFaceValue}
                        </p>
                      )}
                  </>
                ))}

              <div className="text-sm font-medium text-primary ml-1 mt-2">
                Quantity:
              </div>
              <TextField
                value={quantity}
                onChange={onChangeCount}
                onKeyDown={handleKeyDown}
                className="mt-2 border border-selected focus:border-primary-blue focus:outline-none text-sm font-normal text-primary shadow-none"
                type="number"
              />
            </div>
          </div>
          <div className="mt-10 w-full max-w-sm">
            <div className="flex flex-row justify-between p-1 text-lg font-medium text-primary">
              <span>Total:</span>
              <div>{`${total.toFixed(DEFAULT_PRICE_FIXED_DECIMALS)} ${selectedProduct?.defaultCurrencyCode
                }`}</div>
            </div>
            <div className="-mt-1 pr-1">
              <div className="text-xs font-medium text-right">
                {getTotalInUsdt()} {MAIN_CRYPTO_CURRENCY}
              </div>
            </div>
          </div>

          <div className="mt-5 pb-2 grid grid-cols-6 gap-4 w-full max-w-sm">
            <div className="col-start-1 col-end-4">
              <Button
                text="Buy now"
                onClick={onBuynow}
                className="w-full shadow-none"
                disabled={enableBuyNowOrAddToCart()}
              />
            </div>
            <div className="col-start-4 col-end-8">
              <Button
                text="Add to cart"
                inverse
                onClick={onAddToCart}
                className="w-full shadow-none"
                disabled={enableBuyNowOrAddToCart()}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
